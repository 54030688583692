<!--  -->
<template>
    <div id="DomPdf" style="width:794px;height:1123px;border:1px solid #000000;">
        <div style="margin:45px 57px">
            <el-row class="title">
                <el-col :span="6">
                    <div class="blank"></div>
                </el-col>
                <el-col :span="12"><span style="text-align: center; display:inline-block; font-size: 24px;">
                        心狗VIP心脏监护报告
                    </span></el-col>
                <el-col :span="6"><img crossorigin="anonymous" style="width:110px"
                        src="../../../../public/img/logo_xingou.png" /></el-col>
            </el-row>
            <!-- 绘制双横线 -->
            <hr style="height:2px;border:none;border-top:7px double gray;margin-top: 2px;" />
            <el-row>
                <div align="center" style="font-size: 20px; ">
                    VIP基本信息
                </div>
            </el-row>
            <el-row class="info">
                <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                        姓名：{{ report.realName }}</span></el-col>
                <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                        性别：{{ report.gender === 1 || report.gender === '1' ? '男' : '女' }}</span></el-col>
                <el-col :span="8"><span style="text-align: center; display:inline-block; font-size: 16px;">
                        年龄：{{ report.age }}</span></el-col>
            </el-row>
            <el-row>
                <el-col :span="12"><span style="text-align: center; display:inline-block; font-size: 16px;">
                        时间：{{ report.startTime + '——' + report.createTime }} </span></el-col>
                <el-col :span="12"><span style="text-align: center; display:inline-block; font-size: 16px;">
                        基础病信息：{{ report.basicDisease }} </span></el-col>
            </el-row>
            <!-- 绘制单横线 -->
            <hr style="border-top: 2px solid gray;" />
            <div align="center" style="font-size: 20px; ">
                分析结果统计
            </div>
            <div>
                <el-descriptions title="基本概况" :column="1" border :labelStyle="labelStyle">
                    <el-descriptions-item v-for="item in GeneralInfo" :key="item.title" :label="item.title">
                        {{ item.number + item.unit }}
                    </el-descriptions-item>
                </el-descriptions>
                <br>
                <div>在心脏监护过程中，心狗云医检测到以下结论：</div>
                <el-table :data="AbnormalList"  :show-header=false>
                    <el-table-column type="index"></el-table-column>
                    <el-table-column prop="disease" label="结论">
                        <template slot-scope="scope">
                            <span >{{ scope.row.disease }}
                            </span>
                            <!-- <el-input v-if="scope.row.isEditCell" v-model="scope.row.disease" placeholder="结论"
                                @blur="cellBlur(scope.row, scope.column)" style="width:70%" ref="DiseaseRef"></el-input> -->
                        </template>
                    </el-table-column>
                </el-table>

                <el-descriptions title="医生结论" :column="1" border :labelStyle="labelStyle">
                    <el-descriptions-item label="解释检测到的异常">
                        {{ report.conclusionExplanation }}
                    </el-descriptions-item>
                    <el-descriptions-item label="诊疗及生活建议">
                        {{ report.conclusionCure }}
                    </el-descriptions-item>
                </el-descriptions>
                <br>
                <div align="right">
                    签字：
                    <img style="width:30%" :src="Signature" alt="" v-if="Signature">
                </div>
                <div align="right">
                    日期：{{ report.createTime }}
                </div>
                <div align="right" style="font-size: 10px;">
                    *医生建议及诊断结果仅供参考
                </div>
            </div>


        </div>
    </div>
</template>
    
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {

            uid: '',
            report: [],
            GeneralInfo: [
                {
                    title: "静态20s心电:",
                    number: 0,
                    unit: '次'
                },
                {
                    title: "动态测量:",
                    number: 0,
                    unit: '次'
                },
                {
                    title: "心脏监护覆盖天数:",
                    number: 0,
                    unit: '天'
                },
                {
                    title: "时长共计:",
                    number: 0,
                    mounted: function () {
                        this.number = this.usageCount.staticEcg
                    },
                    unit: '分钟'
                },
                {
                    title: "定期监护习惯:",
                    number: 0,
                    mounted: function () {
                        this.number = this.usageCount.staticEcg
                    },
                    unit: ''
                },
            ],
            AbnormalList: [
                // {
                //     disease: '疾病1'
                // },
                // {
                //     disease: '疾病2'
                // },
            ],
            labelStyle: {
                'width': '30%',
                'color': '#606266'
            },
            Signature: '',
            SignatureVisible: false,
            docId: '',
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        // 接口7：查询用户最新一次心电报告
        getLastReport() {
            this.$http({
                url: this.$http.adornUrl('/doctor/vipreport/getLastReport'),
                method: 'post',
                params: this.$http.adornParams({
                    uid: this.uid
                })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log(data)
                    this.report = data.report
                    // 基本信息统计
                    this.usageCount = JSON.parse(data.report.usageCount)
                    console.log(this.usageCount)
                    for (let i = 0; i < this.GeneralInfo.length; i++) {
                        let GeneralInfo = this.GeneralInfo[i]
                        let sequence = ['staticEcg', 'dynamicEcg', 'days', 'duration', 'style']
                        GeneralInfo.number = this.usageCount[sequence[i]]
                    }
                    // 异常项统计
                    let result = JSON.parse(data.report.abnormalCondition)  //数组列表形式，若不是，则加JSON.parse进行转换
                    if (result) {
                        let uniqueResult = Array.from(new Set(result)) // 去重
                        var arr = []
                        for (let i = 0; i < uniqueResult.length; i++) {
                            let value = uniqueResult[i].toString()
                            let obj = {
                                //属性1
                                disease: value
                            };
                            arr.push(obj);
                        }
                        this.AbnormalList = arr
                    } else {
                        this.AbnormalList = []
                    }

                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },

        // 接口10：医生查询个人签名
        getSignature() {
            this.$http({
                url: this.$http.adornUrl('/doctor/doctorinfo/getSignature'),
                method: 'get',
                params: this.$http.adornParams({ docId: this.docId })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log(data)
                    this.Signature = data.signature
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        console.log("sblzy");
        console.log(this.$route.query.uid);
        console.log(this.$route.query.docId);
        this.uid = this.$route.query.uid
        this.docId = this.$route.query.docId
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.getLastReport()
        this.getSignature()
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.blank {
    border-radius: 4px;
    height: 18px;
}
.el-descriptions .is-bordered .el-descriptions-item__cell {
    padding: 6px 10px;
}
</style>